.error {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #21232a;
    color: #fff;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    padding: 0;
    min-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.8);
    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.8);
    display: table;
    font-family: 'Open Sans', Arial, sans-serif;

    font-family: sans-serif;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    height: 100%;
    background-color: #21232a;
    [type='button'] {
        -webkit-appearance: button;
    }

    article {
        display: block;
    }

    aside {
        display: block;
    }

    .footer {
        display: block;
        position: fixed;
        text-align: center;
        width: 100%;
        height: 40px;
        left: 0;
        bottom: 0;
        color: #a0a0a0;
        font-size: 14px;
    }

    header {
        display: block;
    }

    nav {
        display: block;
    }

    section {
        display: block;
    }

    h1 {
        font-size: 2em;
        margin: 0.67em 0;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        font-size: 36px;

        small {
            font-size: 68%;
            font-weight: 400;
            line-height: 1;
            color: #777;
        }
    }

    figcaption {
        display: block;
    }

    figure {
        display: block;
        margin: 1em 40px;
    }

    main {
        display: block;
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    pre {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    a {
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
        text-decoration: none;
        color: #fff;
        font-size: inherit;
        border-bottom: dotted 1px #707070;

        &:active {
            outline-width: 0;
        }

        &:hover {
            outline-width: 0;
        }
    }

    abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        text-decoration: underline dotted;
    }

    b {
        font-weight: inherit;
        font-weight: bolder;
    }

    strong {
        font-weight: inherit;
        font-weight: bolder;
    }

    code {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    kbd {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    samp {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    dfn {
        font-style: italic;
    }

    mark {
        background-color: #ff0;
        color: #000;
    }

    small {
        font-size: 80%;
    }

    sub {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        bottom: -0.25em;
    }

    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        top: -0.5em;
    }

    audio {
        display: inline-block;
        &:not([controls]) {
            display: none;
            height: 0;
        }
    }

    video {
        display: inline-block;
    }

    img {
        border-style: none;
    }

    svg {
        &:not(:root) {
            overflow: hidden;
        }
    }

    button {
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;

        &::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        &:-moz-focusring {
            outline: 1px dotted ButtonText;
        }
    }

    input {
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        overflow: visible;
    }

    optgroup {
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
    }

    select {
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        text-transform: none;
    }

    textarea {
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        overflow: auto;
    }

    [type='reset'] {
        -webkit-appearance: button;

        &::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        &:-moz-focusring {
            outline: 1px dotted ButtonText;
        }
    }

    [type='submit'] {
        -webkit-appearance: button;

        &::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        &:-moz-focusring {
            outline: 1px dotted ButtonText;
        }
    }

    [type='button'] {
        &::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        &:-moz-focusring {
            outline: 1px dotted ButtonText;
        }
    }

    fieldset {
        border: 1px solid silver;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;
    }

    legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal;
    }

    progress {
        display: inline-block;
        vertical-align: baseline;
    }

    [type='checkbox'] {
        box-sizing: border-box;
        padding: 0;
    }

    [type='radio'] {
        box-sizing: border-box;
        padding: 0;
    }

    [type='number'] {
        &::-webkit-inner-spin-button {
            height: auto;
        }

        &::-webkit-outer-spin-button {
            height: auto;
        }
    }

    [type='search'] {
        -webkit-appearance: textfield;
        outline-offset: -2px;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }

        &::-webkit-search-decoration {
            -webkit-appearance: none;
        }
    }

    details {
        display: block;
    }

    menu {
        display: block;
    }

    summary {
        display: list-item;
    }

    canvas {
        display: inline-block;
    }

    template {
        display: none;
    }

    [hidden] {
        display: none;
    }

    .lead {
        color: silver;
        font-size: 21px;
        line-height: 1.4;
    }

    .cover {
        display: table-cell;
        vertical-align: middle;
        padding: 0 20px;
    }
}
